import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import { fetchMyEnrollment } from '@/services/enrollment';

export interface EnrollmentState {
	enrollment: IEnrollment | undefined;
}

const initialState: EnrollmentState = {
	enrollment: undefined
};

export const fetchMyEnrollmentAsync = createAsyncThunk('enrollment/fetchMyEnrollment', async () => {
	const res = await fetchMyEnrollment();
	return res;
});

export const enrollmentSlice = createSlice({
	name: 'enrollment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(
			fetchMyEnrollmentAsync.fulfilled,
			(state: EnrollmentState, action: PayloadAction<FetchMyEnrollment>) => {
				const { user, program } = action.payload;
				state.enrollment = {
					user,
					program: program as EnrollmentProgram[]
				};
			}
		);
	}
});

export const selectEnrollmentState = (state: RootState) => state.enrollment.enrollment;

export default enrollmentSlice.reducer;
