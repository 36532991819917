import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import { EDIT_TYPE } from '@/constants/common';
import { MESSAGE_POST_STEP, MESSAGE_TYPE, MESSAGE_VISIBILITY } from '@/constants/message';
import { fetchMessages } from '@/services/message';

type MESSAGE_TYPE_WITH_ALL = MESSAGE_TYPE | '';

export interface FeedState {
	messages: IFetchMessage[];
	/** 是否loading中 */
	loading: boolean;
	/** 是否加载了所有 */
	loadAll: boolean;
	/** 当前查看类型 */
	type: MESSAGE_TYPE_WITH_ALL;
	/** 当前要post的内容 */
	post: MESSAGE_POST;
}
const initialState: FeedState = {
	messages: [],
	loading: false,
	loadAll: false,
	type: '',
	post: {
		open: false,
		editType: EDIT_TYPE.CREATE,
		step: MESSAGE_POST_STEP.NORMAL,
		type: MESSAGE_TYPE.JOB,
		id: '',
		content: '',
		visibility: MESSAGE_VISIBILITY.PUBLIC,
		visibilityTrainingIds: [],
		extendType: ''
	}
};

export const refreshMessages = createAsyncThunk(
	'feed/refreshMessages',
	async (payload: IFetchMessagesPayload) => {
		const messages = await fetchMessages(payload);
		return messages;
	}
);

/** 加载更多消息, 带时间戳 */
export const moreMessage = createAsyncThunk(
	'feed/moreMessage',
	async (payload: IFetchMessagesPayload) => {
		if (payload.timestamp) {
			const messages = await fetchMessages(payload);
			return {
				messages
			};
		}
		return {
			messages: []
		};
	}
);

export const feedSlice = createSlice({
	name: 'feed',
	initialState,
	reducers: {
		updateMessageType: (state: FeedState, action: PayloadAction<string>) => {
			state.type = action.payload as MESSAGE_TYPE_WITH_ALL;
		},
		/** 更新要发布的post的数据 */
		updatePostMessage: (state: FeedState, action: PayloadAction<MESSAGE_POST>) => {
			state.post = action.payload;
		},
		cleanPostMessage: (state: FeedState) => {
			state.post = initialState.post;
		}
	},
	extraReducers: builder => {
		builder.addCase(refreshMessages.pending, (state: FeedState) => {
			state.loading = true;
		});
		builder.addCase(
			refreshMessages.fulfilled,
			(state, action: PayloadAction<IFetchMessage[]>) => {
				state.messages = action.payload;
				state.loading = false;
			}
		);
		builder.addCase(moreMessage.rejected, (state: FeedState) => {
			state.loading = true;
		});
		builder.addCase(
			moreMessage.fulfilled,
			(
				state,
				action: PayloadAction<{
					messages: IFetchMessage[];
				}>
			) => {
				if (action.payload.messages) {
					state.messages = [...state.messages, ...action.payload.messages];
				}
				if (action.payload.messages.length === 0) {
					state.loadAll = true;
				}
				state.loading = false;
			}
		);
	}
});

export const { updateMessageType, updatePostMessage, cleanPostMessage } = feedSlice.actions;
export const selectFeedState = (state: RootState) => state.feed;
export default feedSlice.reducer;
