import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import styled from 'styled-components';

import CalendarIcon from '@/assets/icons/calendar-icon--black.svg';
import BlueProgramCard from '@/assets/images/program-card--blue.svg';
import GreenProgramCard from '@/assets/images/program-card--green.svg';
import YellowProgramCard from '@/assets/images/program-card--yellow.svg';
import { EProgramStatus } from '@/components/Pages/StudyPage/ProgramList/ProgramList';
import ProgressBar from '@/components/Shares/ProgressBar';
import Button from '@/components/UI/Button';
import EProgramType, { PROGRAM_TYPE_NAME } from '@/constants/program';
import { boxHoverAnimation, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import getDurationPercentage from '@/utils/date';

const { primaryYellow, primaryBlue, primaryGreen } = color;

const Card = styled.div`
	background-color: ${color.whiteColor};
	${boxHoverAnimation(4)};
	border: 1px solid #000;
	min-height: 229px;
`;

const CardHeader = styled.div<{ color: string }>`
	background-color: ${props => props.color};
	padding: 6px 16px;
`;

const CardBody = styled.div`
	background-color: ${color.whiteColor};
	display: flex;
	justify-content: space-between;
	min-height: 174px;
	padding: 16px 0 0 16px;
`;

const CardInfoContainer = styled.div``;

const CardTitle = styled.h3`
	font-size: 18px;
	font-weight: 600;
	${textEllipsis(2)};
	margin: 0;
	margin-bottom: 8px;
	min-height: 53px;
`;
const CardDate = styled.div`
	align-items: center;
	display: flex;
	font-size: 14px;
	gap: 4px;
`;

const CardPercentage = styled.div`
	margin: 10px 0;
	width: 250px;
`;

const CardButton = styled.div``;
const CardImage = styled.div`
	display: none;
	margin-right: 38px;
	margin-top: 19px;
	@media ${devices.mobile} {
		display: block;
	}
`;

const PendingContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;

const PendingIcon = styled.div`
	background-color: ${color.pendingIconColor};
	border: solid 1px ${color.blackColor};
	border-radius: 8px;
	height: 8px;
	width: 8px;
`;

const PendingText = styled.span`
	font-size: 14px;
	margin: 0;
	padding: 0;
`;

interface ProgramCardProps {
	program: EnrollmentProgram & { orderStatus?: EProgramStatus };
}

const EnrolledProgramCardConfig = {
	[EProgramType.TRAINING]: {
		color: primaryYellow,
		svg: <YellowProgramCard />
	},
	[EProgramType.VIDEO]: {
		color: primaryGreen,
		svg: <GreenProgramCard />
	},
	[EProgramType.INTERNSHIP]: {
		color: primaryBlue,
		svg: <BlueProgramCard />
	}
};

const ProgramCard: React.FC<ProgramCardProps> = ({ program }) => {
	const router = useRouter();
	const {
		type = EProgramType.TRAINING,
		name,
		commenceCourseDate,
		completeDate,
		orderStatus
	} = program;
	const percentage = getDurationPercentage(commenceCourseDate, completeDate);
	const isFinished = dayjs().isAfter(dayjs(completeDate));
	const programLink = `/study/program?programId=${program.id}`;

	return (
		<Card>
			<CardHeader color={EnrolledProgramCardConfig[type as EProgramType].color}>
				{PROGRAM_TYPE_NAME[type as EProgramType]}
			</CardHeader>
			<CardBody>
				<CardInfoContainer>
					{orderStatus === EProgramStatus.PENDING ? (
						<CardTitle>{name}</CardTitle>
					) : (
						<Link href={programLink}>
							<CardTitle>{name}</CardTitle>
						</Link>
					)}
					<CardDate>
						<CalendarIcon />
						结课日期: {dayjs(completeDate).format('YYYY/MM/DD')}
					</CardDate>
					<CardPercentage>
						<ProgressBar percentage={percentage} />
					</CardPercentage>
					{orderStatus === EProgramStatus.PENDING ? (
						<PendingContainer>
							<PendingIcon />
							<PendingText>课程审核中，请耐心等待…</PendingText>
						</PendingContainer>
					) : (
						<CardButton>
							<Button
								color={isFinished ? 'white' : 'default'}
								onClick={() => router.push(programLink)}
							>
								{isFinished ? '已完成' : '继续学习'}
							</Button>
						</CardButton>
					)}
				</CardInfoContainer>
				<CardImage>{EnrolledProgramCardConfig[type as EProgramType].svg}</CardImage>
			</CardBody>
		</Card>
	);
};

export default ProgramCard;
