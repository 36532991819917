import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { RootState } from '..';

import { EProgramStatus } from '@/components/Pages/StudyPage/ProgramList/ProgramList';
import { NAV_KEY } from '@/constants/study';
import { fetchCalendarData, fetchProgramsList } from '@/services/study';
import pushHistoryState from '@/utils/pushHistoryState';

export interface StudyState {
	/** 当前tab 的 key */
	activeKey: NAV_KEY;
	/** 我的课程，所有的program数据 */
	programs: StudyProgramListItem[];
	/** 日历数据 */
	calendarData: ICalendarDataItem[];
	/** 日历月份 */
	calendarMonth: number;
}

const initialState: StudyState = {
	activeKey: NAV_KEY.STUDY,
	programs: [],
	calendarData: [],
	calendarMonth: dayjs().month() + 1
};

// 更新日历数据
export const refreshCalendarData = createAsyncThunk(
	'study/refreshCalendarData',
	async (payload: { month: number }) => {
		// month: 1~12
		const start = dayjs()
			.month(payload.month - 1)
			.startOf('month')
			.toISOString();
		const end = dayjs()
			.month(payload.month + 1)
			.endOf('month')
			.toISOString();
		if (start && end) {
			const data = await fetchCalendarData({ start, end });
			return {
				month: payload.month,
				data
			};
		}
		return {
			month: payload.month,
			data: []
		};
	}
);
/** 更新学习中心program列表数据 */
export const refreshStudyPrograms = createAsyncThunk(
	'study/refreshPrograms',
	async (): Promise<IFetchPrograms> => {
		const result = await fetchProgramsList();
		return result;
	}
);

const updateHistoryState = (state: StudyState) => {
	pushHistoryState({ queryParams: { tab: state.activeKey } });
};

export const studySlice = createSlice({
	name: 'study',
	initialState,
	reducers: {
		initStudy: (state: StudyState, action: PayloadAction<{ activeKey: NAV_KEY }>) => {
			state.activeKey = action.payload.activeKey;
			updateHistoryState(state);
		},
		updateStudyActiveKey: (state: StudyState, action: PayloadAction<NAV_KEY>) => {
			state.activeKey = action.payload;
			updateHistoryState(state);
		}
	},
	extraReducers: builder => {
		builder.addCase(
			refreshCalendarData.fulfilled,
			(
				state,
				action: PayloadAction<{
					month: number;
					data: ICalendarDataItem[];
				}>
			) => {
				state.calendarMonth = action.payload.month;
				state.calendarData = action.payload.data;
			}
		);
		builder.addCase(
			refreshStudyPrograms.fulfilled,
			(state, action: PayloadAction<IFetchPrograms>) => {
				const list = [
					...action.payload.pending
						.sort((a, b) => (dayjs(a.completeDate).isBefore(b.completeDate) ? 1 : -1))
						.map(item => {
							(item as StudyProgramListItem).orderStatus = EProgramStatus.PENDING;
							return item;
						}),
					...action.payload.programs
						.sort((a, b) =>
							dayjs(a.latestLesson?.commenceDate).isBefore(
								b.latestLesson?.commenceDate
							)
								? -1
								: 1
						)
						.map(item => ({
							...item,
							orderStatus:
								dayjs().isAfter(dayjs(item.commenceCourseDate)) &&
								dayjs().isBefore(dayjs(item.completeDate))
									? EProgramStatus.IN_PROGRESS
									: EProgramStatus.FINISHED
						}))
				];
				state.programs = list;
			}
		);
	}
});

export const { initStudy, updateStudyActiveKey } = studySlice.actions;
export const selectStudyState = (state: RootState) => state.study;
export default studySlice.reducer;
