export const MESSAGE_POST_IMAGES_NUM_MAX = 10;
export const MESSAGE_POST_IMAGES_SIZE_MAX = 10; // 10MB

/** 消息类型 */
export enum MESSAGE_TYPE {
	/** 工作机会 */
	JOB = 'job',
	/** 资料分享 */
	MATERIAL = 'material',
	/** 课程 */
	COURSE = 'course',
	/** 活动 */
	EVENT = 'event',
	/** 面试题 */
	INTERVIEW = 'interview',
	/** 学习笔记 */
	NOTE = 'note'
}

export const MESSAGE_TYPE_TEXT = {
	[MESSAGE_TYPE.JOB]: '工作机会',
	[MESSAGE_TYPE.MATERIAL]: '资料分享',
	[MESSAGE_TYPE.COURSE]: '课程',
	[MESSAGE_TYPE.EVENT]: '活动',
	[MESSAGE_TYPE.INTERVIEW]: '面试题',
	[MESSAGE_TYPE.NOTE]: '学习笔记'
};

/** 消息可见范围 */
export enum MESSAGE_VISIBILITY {
	/** 公开 */
	PUBLIC = 'public',
	/** 注册用户登陆后 */
	LOGIN = 'login',
	/** 学生 */
	// STUDENT = 'student',
	/** 班级 */
	CLASS = 'class'
}
export const MESSAGE_VISIBILITY_TEXT = {
	[MESSAGE_VISIBILITY.PUBLIC]: '公开',
	[MESSAGE_VISIBILITY.LOGIN]: '注册用户',
	// [MESSAGE_VISIBILITY.STUDENT]: '学生可见',
	[MESSAGE_VISIBILITY.CLASS]: '班级可见'
};

export enum MESSAGE_POST_STEP {
	NORMAL = 'normal',
	VISIBILITY = 'visibility',
	PPT = 'ppt',
	FILE = 'file',
	EVENT = 'event',
	IMAGE = 'image'
}

export enum MESSAGE_EXTEND_TYPE {
	JOB = 'job',
	IMAGE = 'image',
	FILE = 'file',
	PPT = 'ppt',
	EVENT = 'event'
}

export const messageIcons = [
	// {
	// 	icon: '/image/study/message/message-ext-job.svg',
	// 	label: '工作',
	// 	type: MESSAGE_EXTEND_TYPE.JOB
	// },
	{
		icon: '/image/study/message/message-ext-img.svg',
		label: '图片',
		type: MESSAGE_EXTEND_TYPE.IMAGE
	},
	{
		icon: '/image/study/message/message-ext-file.svg',
		label: '文件',
		type: MESSAGE_EXTEND_TYPE.FILE
	},
	{
		icon: '/image/study/message/message-ext-ppt.svg',
		label: 'PPT',
		type: MESSAGE_EXTEND_TYPE.PPT
	},
	{
		icon: '/image/study/message/message-ext-event.svg',
		label: '活动',
		type: MESSAGE_EXTEND_TYPE.EVENT
	}
];

export default {};
