import queryString from 'query-string';

import { EUserPreferenceTargetList, GET_USER_PREFERENCE_ENDPOINTS } from '@/constants/user';
import {
	IDeleteUserPreferencePayload,
	IFetchLikeCountByItemIdListPayload,
	IFetchLikeCountItemsListResponse,
	IFetchUserPreferenceItemsList,
	IUpdateOrDeleteUserPreferenceResponse,
	IUpdateUserPreferencePayload
} from '@/interfaces/fetch/userPreference.fetch';
import request, { HTTP_METHOD } from '@/utils/request';

/**
 * Fetches a list of user preferences (liked or archived items) by user ID.
 *
 * @param {EUserPreferenceTargetList} itemType - The type of preference to fetch (likes or archived).
 * @returns {Promise<IFetchUserPreferenceItemsList>} A promise that resolves to the user's preference items list.
 */
export const fetchLikeOrArchivedListByUserId = async (
	itemType: EUserPreferenceTargetList
): Promise<IFetchUserPreferenceItemsList> => {
	return request<IFetchUserPreferenceItemsList>(
		HTTP_METHOD.GET,
		`/common/userPreferences/${
			GET_USER_PREFERENCE_ENDPOINTS.GET_LIKES_OR_ARCHIVED_LIST
		}?${queryString.stringify({ itemType })}`
	);
};

/**
 * Fetches the like counts for a list of items.
 *
 * @param {IFetchLikeCountByItemIdListPayload} payload - The payload containing the table names and item IDs.
 * @returns {Promise<IFetchLikeCountItemsListResponse[]>} A promise that resolves to the list of like counts for the specified items.
 */
export const fetchLikeCountByItemIdList = async (
	payload: IFetchLikeCountByItemIdListPayload
): Promise<IFetchLikeCountItemsListResponse[]> => {
	const query = queryString.stringify({
		tableName: payload.tableName,
		itemId: payload.itemId.join(',')
	});
	return request<IFetchLikeCountItemsListResponse[]>(
		HTTP_METHOD.GET,
		`/common/userPreferences/${GET_USER_PREFERENCE_ENDPOINTS.GET_LIKES_QUANTITY}?${query}`
	);
};

/**
 * Updates a user's preference (like or archive) for a specific item.
 *
 * @param {IUpdateUserPreferencePayload} payload - The payload containing the item ID, item type, and table name.
 * @returns {Promise<IUpdateOrDeleteUserPreferenceResponse>} A promise that resolves to the response of the update operation.
 */
export const updateUserPreference = async (
	payload: IUpdateUserPreferencePayload
): Promise<IUpdateOrDeleteUserPreferenceResponse> => {
	return request<IUpdateOrDeleteUserPreferenceResponse>(
		HTTP_METHOD.PUT,
		'/common/userPreferences',
		{ body: payload }
	);
};

/**
 * Deletes a user's preference (like or archive) for a specific item.
 *
 * @param {IDeleteUserPreferencePayload} payload - The payload containing the item ID, item type, and table name.
 * @returns {Promise<IUpdateOrDeleteUserPreferenceResponse>} A promise that resolves to the response of the delete operation.
 */
export const deleteUserPreferenceByUserId = async (
	payload: IDeleteUserPreferencePayload
): Promise<IUpdateOrDeleteUserPreferenceResponse> => {
	return request<IUpdateOrDeleteUserPreferenceResponse>(
		HTTP_METHOD.DELETE,
		'/common/userPreferences',
		{
			body: payload
		}
	);
};
