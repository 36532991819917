import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import { EUserPreferenceTargetList } from '@/constants/user';
import {
	IDeleteUserPreferencePayload,
	IFetchLikeCountByItemIdListPayload,
	IFetchLikeCountItemsListResponse,
	IFetchUserPreferenceItemsList,
	ILikeCountItem,
	IUpdateUserPreferencePayload
} from '@/interfaces/fetch/userPreference.fetch';
import {
	deleteUserPreferenceByUserId as deleteUserPreferenceByUserIdService,
	fetchLikeCountByItemIdList as fetchLikeCountByItemIdListService,
	fetchLikeOrArchivedListByUserId as fetchLikeOrArchivedListService,
	updateUserPreference as updateUserPreferenceService
} from '@/services/userPreference';

interface UserPreferenceState {
	likeItems: string[];
	archivedItems: string[];
	likeCount: { [key: string]: number };
}

const initialState: UserPreferenceState = {
	likeItems: [],
	archivedItems: [],
	likeCount: {}
};

export const fetchLikeOrArchivedListByUserId = createAsyncThunk(
	'userPreference/fetchLikeOrArchivedListByUserId',
	async (itemType: EUserPreferenceTargetList): Promise<string[]> => {
		const response: IFetchUserPreferenceItemsList = await fetchLikeOrArchivedListService(
			itemType
		);
		return response.Items.map(item => item.tableName_itemId);
	}
);

export const fetchLikeCountByItemIdList = createAsyncThunk(
	'userPreference/fetchLikeCountByItemIdList',
	async (payload: IFetchLikeCountByItemIdListPayload): Promise<ILikeCountItem[]> => {
		const response: IFetchLikeCountItemsListResponse[] =
			await fetchLikeCountByItemIdListService(payload);
		return response.flatMap(res => res.Items);
	}
);

export const updateUserPreference = createAsyncThunk(
	'userPreference/updateUserPreference',
	async (payload: IUpdateUserPreferencePayload): Promise<void> => {
		await updateUserPreferenceService(payload);
	}
);

export const deleteUserPreferenceByUserId = createAsyncThunk(
	'userPreference/deleteUserPreferenceByUserId',
	async (payload: IDeleteUserPreferencePayload): Promise<void> => {
		await deleteUserPreferenceByUserIdService(payload);
	}
);

const userPreferenceSlice = createSlice({
	name: 'userPreference',
	initialState,
	reducers: {
		addUserPreference: (
			state,
			action: PayloadAction<{
				itemType: EUserPreferenceTargetList;
				tableName_itemId: string;
				itemId: string;
			}>
		) => {
			const { itemType, tableName_itemId: tableNameItemId, itemId } = action.payload;

			const itemList =
				itemType === EUserPreferenceTargetList.LIKE_ITEMS
					? state.likeItems
					: state.archivedItems;

			if (!itemList.includes(tableNameItemId)) {
				itemList.push(tableNameItemId);

				if (itemType === EUserPreferenceTargetList.LIKE_ITEMS) {
					state.likeCount[itemId] = (state.likeCount[itemId] || 0) + 1;
				}
			}
		},
		deleteUserPreference: (
			state,
			action: PayloadAction<{ itemType: string; tableName_itemId: string; itemId: string }>
		) => {
			const { itemType, tableName_itemId: tableNameItemId, itemId } = action.payload;

			const itemList =
				itemType === EUserPreferenceTargetList.LIKE_ITEMS
					? state.likeItems
					: state.archivedItems;

			state[
				itemType === EUserPreferenceTargetList.LIKE_ITEMS ? 'likeItems' : 'archivedItems'
			] = itemList.filter(item => item !== tableNameItemId);

			if (itemType === EUserPreferenceTargetList.LIKE_ITEMS) {
				if (state.likeCount[itemId] > 0) {
					state.likeCount[itemId] -= 1;
				}
			}
		},
		updateLikeCount: (state, action: PayloadAction<ILikeCountItem[]>) => {
			action.payload.forEach(countItem => {
				state.likeCount[countItem.itemId] = countItem.counts;
			});
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchLikeOrArchivedListByUserId.fulfilled, (state, action) => {
			state.likeItems = action.payload;
		});

		builder.addCase(fetchLikeCountByItemIdList.fulfilled, (state, action) => {
			action.payload.forEach(countItem => {
				state.likeCount[countItem.itemId] = countItem.counts;
			});
		});
	}
});

export const { addUserPreference, deleteUserPreference, updateLikeCount } =
	userPreferenceSlice.actions;

export const selectIsLiked = (state: RootState, itemId: string): boolean => {
	return state.userPreference.likeItems.includes(itemId);
};
export const selectLikeCount = (state: RootState, itemId: string): number => {
	return state.userPreference.likeCount[itemId] || 0;
};
export const selectLikeItems = (state: RootState) => state.userPreference.likeItems;

export default userPreferenceSlice.reducer;
