export enum EFormKey {
	/** 默认 */
	DEFAULT = 'default',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	COUNTRY_CODE = 'countryCode',
	PHONE = 'phone'
}

export enum EName {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName'
}

/**
 * phone country code
 */
export enum ECountryCode {
	AUSTRALIA = '+61',
	HONGKONG = '+85',
	CHINA = '+86'
}
export enum EFormItemType {
	TEXT = 'text',
	TEXTAREA = 'textarea',
	NAME = 'name',
	PHONE = 'phone',
	EMAIL = 'email',
	SELECT = 'select'
}

/** 表单学历选项 */
export const DegreeOptions = [
	{
		label: '高中',
		value: 'highSchool'
	},
	{
		label: '本科',
		value: 'bachelor'
	},
	{
		label: '硕士',
		value: 'master'
	},
	{
		label: '博士',
		value: 'doctor'
	},
	{
		label: '其他',
		value: 'other'
	}
];

export default {};
