import { Pagination as MuiPagination } from '@mui/material';
import styled from 'styled-components';

interface PaginationProps {
	page: number;
	totalPages: number;
	handlePageChange: (page: number) => void;
}

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	.MuiButtonBase-root {
		border-color: transparent;
		border-radius: 2px;
		height: 28px;
		min-width: unset;
		width: 28px;
		&.Mui-selected {
			background-color: #000;
			color: #fff;
		}
	}
	.MuiPaginationItem-previousNext {
		background-color: #fff;
		border: 1px solid #000;
	}
`;

const Pagination: React.FC<PaginationProps> = props => {
	const { page, totalPages, handlePageChange } = props;
	if (totalPages < 1) return null;
	return (
		<PaginationContainer>
			<MuiPagination
				page={page}
				count={totalPages}
				variant="outlined"
				shape="rounded"
				onChange={(event: React.ChangeEvent<unknown>, value: number) =>
					handlePageChange(value)
				}
			/>
		</PaginationContainer>
	);
};

export default Pagination;
