import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ProgramCard from './components/ProgramCard';
import Pagination from '@/components/Shares/Pagination';
import SearchBar from '@/components/Shares/SearchBar';
import Button from '@/components/UI/Button';
import { fetchProgramsList } from '@/services/study';
import { devices, sizes } from '@/styles/variables';
import imageLoader from '@/utils/loader';

export enum EProgramStatus {
	ALL = 'all',
	PENDING = 'pending',
	IN_PROGRESS = 'in_progress',
	FINISHED = 'finished'
}

const programStatusLabel = {
	[EProgramStatus.ALL]: '全部',
	[EProgramStatus.PENDING]: '待审核',
	[EProgramStatus.IN_PROGRESS]: '学习中',
	[EProgramStatus.FINISHED]: '已完成'
};

const FilterContainer = styled.div`
	@media ${devices.mobile} {
		align-items: center;
		display: flex;
	}
	& .MuiToggleButtonGroup-grouped {
		border-radius: 2px;
		&:not(:first-of-type) {
			border-color: #000;
		}
		&.MuiToggleButton-root {
			background-color: #fff;
			border: solid 1px #000;
			color: #000;
			min-width: 74px;
			padding: 4px 16px;
		}
		&.Mui-selected {
			background-color: #000;
			color: #fff;
		}
	}
`;

const ProgramListContainer = styled.div`
	padding: 26px 0 32px;
`;

const PaginationContainer = styled.div`
	margin-top: 32px;
`;

const EmptyContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	padding: 24px auto;
	width: 100%;
`;
const Description = styled.p`
	font-size: 14px;
	line-height: 1;
	margin-bottom: 16px;
	margin-top: 24px;
`;

const SearchContainer = styled.div`
	border-radius: 2px;
	display: flex;
	height: 34px;
	margin-top: 16px;
	width: 100%;
	@media ${devices.mobile} {
		width: 264px;
		margin-top: 0;
		padding: 0 16px;
	}
`;

type Program = EnrollmentProgram & { orderStatus?: EProgramStatus };

const ProgramList = () => {
	const [programs, setPrograms] = useState<Program[]>([]);
	const [pagePrograms, setPagePrograms] = useState<Program[]>([]);
	// state
	const [programStatus, setProgramStatus] = useState(EProgramStatus.ALL);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(6);
	const [totalPages, setTotalPages] = useState(1);
	const [search, setSearch] = useState<string>('');

	const { run } = useRequest(fetchProgramsList, {
		manual: true,
		onSuccess: result => {
			setPrograms([
				...result.pending
					.sort((a, b) => (dayjs(a.completeDate).isBefore(b.completeDate) ? 1 : -1))
					.map(item => {
						(item as Program).orderStatus = EProgramStatus.PENDING;
						return item;
					}),
				...result.programs.sort((a, b) =>
					dayjs(a.completeDate).isBefore(b.completeDate) ? 1 : -1
				)
			]);
		}
	});

	// 定义分页个数
	const handleSetPageSize = () => {
		if (window.innerWidth < sizes.laptop) {
			setPageSize(3);
		} else {
			setPageSize(6);
		}
	};

	useEffect(() => {
		run();
		handleSetPageSize();
		window.addEventListener('resize', handleSetPageSize);
		return () => window.removeEventListener('resize', handleSetPageSize);
	}, []);

	useEffect(() => {
		if (programs) {
			let list = programs;
			if (programStatus === EProgramStatus.PENDING) {
				list = list.filter(item => item.orderStatus === programStatus);
			}
			if (programStatus === EProgramStatus.FINISHED) {
				list = list.filter(
					item =>
						dayjs().isAfter(dayjs(item.completeDate)) &&
						item.orderStatus !== EProgramStatus.PENDING
				);
			}
			if (programStatus === EProgramStatus.IN_PROGRESS) {
				list = list.filter(
					item =>
						dayjs().isBefore(dayjs(item.completeDate)) &&
						dayjs().isAfter(dayjs(item.commenceCourseDate)) &&
						item.orderStatus !== EProgramStatus.PENDING
				);
			}
			if (search) {
				list = list.filter(item => new RegExp(search, 'i').test(item.name || ''));
			}
			setTotalPages(Math.ceil(list.length / pageSize));
			list = list.slice(pageSize * (page - 1), pageSize * page);
			setPagePrograms(list);
		}
	}, [programs, programStatus, page, pageSize, search]);

	const handleChangProgramStatusFilter = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: EProgramStatus
	) => {
		setPage(1);
		setProgramStatus(newAlignment);
	};

	const handleSearch = (searchValue: string = '') => {
		setSearch(searchValue);
	};

	return (
		<div>
			<FilterContainer>
				<ToggleButtonGroup
					value={programStatus}
					exclusive
					onChange={handleChangProgramStatusFilter}
				>
					{Object.entries(programStatusLabel).map(([key, label]) => (
						<ToggleButton value={key} key={key}>
							{label}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				<SearchContainer>
					<SearchBar
						searchValue={search}
						placeholder="搜索您的课程"
						handleChange={setSearch}
						handleSubmit={handleSearch}
					/>
				</SearchContainer>
			</FilterContainer>
			<ProgramListContainer>
				{pagePrograms && pagePrograms.length > 0 && (
					<>
						<Grid
							container
							columnSpacing={{
								miniMobile: 0,
								largeLaptop: 8.5
							}}
							rowSpacing={4}
						>
							{pagePrograms.map(program => (
								<Grid item key={program.id} miniMobile={12} largeLaptop={6}>
									<ProgramCard program={program} />
								</Grid>
							))}
						</Grid>
						<PaginationContainer>
							<Pagination
								page={page}
								totalPages={totalPages}
								handlePageChange={value => setPage(value)}
							/>
						</PaginationContainer>
					</>
				)}
				{(!pagePrograms || pagePrograms.length === 0) && (
					<EmptyContainer>
						<Image
							src="/image/empty.png"
							alt="empty"
							unoptimized
							loader={imageLoader}
							width={102}
							height={96}
						/>
						<Description>暂无课程，快去选择适合你的课程吧！</Description>
						<Link href="/program-courses">
							<Button>选择课程</Button>
						</Link>
					</EmptyContainer>
				)}
			</ProgramListContainer>
		</div>
	);
};

export default ProgramList;
