import request, { HTTP_METHOD } from '@/utils/request';

export const fetchCreateMessage = async (
	payload: IFetchMessageSavePayload
): Promise<IFetchMessage> => {
	return request<FetchData<IFetchMessage>>(HTTP_METHOD.POST, '/message', {
		body: payload
	});
};

export const fetchUpdateMessage = async (
	payload: IFetchMessageSavePayload
): Promise<IFetchMessage> => {
	return request<FetchData<IFetchMessage>>(HTTP_METHOD.PATCH, '/message', {
		body: payload
	});
};

export const fetchDeleteMessage = async (id: string): Promise<DeleteResponse> => {
	return request<FetchData<DeleteResponse>>(HTTP_METHOD.DELETE, `/message/${id}`);
};

export const fetchMessages = async (payload: IFetchMessagesPayload): Promise<IFetchMessage[]> => {
	return request<FetchData<IFetchMessage[]>>(HTTP_METHOD.GET, '/message', {
		query: payload
	});
};

export default {};
