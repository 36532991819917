import { sample } from 'lodash';
import Image from 'next/image';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';
import imageLoader from '@/utils/loader';

type TSize = 'small' | 'medium';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;
const TitleContainer = styled.div<{ checked: boolean; size: TSize }>`
	align-items: center;
	background-color: ${props => (props.checked ? '#ffe400' : 'unset')};
	border: solid 1px #000;
	border-radius: 2px;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	padding: ${props => (props.size === 'small' ? '4px 16px' : '8px 16px')};
	width: 100%;
`;

const TitleStart = styled.div`
	align-items: center;
	display: flex;
	gap: 10px;
`;
const TitleEnd = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;
`;
const CheckBox = styled.div`
	align-items: center;
	border: solid 1px #000;
	border-radius: 50%;
	display: flex;
	height: 16px;
	justify-content: center;
	width: 16px;
`;
const SelectIcon = styled(Image)``;
const ContentContainer = styled.div`
	border: solid 1px #000;
	border-radius: 1px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	width: 100%;
`;
const Title = styled.p`
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	margin: 0;
	margin-bottom: -8px;
`;

const InfosContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const InfoItem = styled.p`
	font-size: 14px;
	line-height: 1;
	margin: 0;
`;
const TipItem = styled.p`
	color: ${color.primaryColor};
	font-size: 14px;
	line-height: 1;
	margin: 0;
`;

export enum PAY_METHOD {
	/** 信用卡 */
	BANK_CARD,
	/** 转账 */
	TRANSFER
}

interface PayMethodCardProps {
	payMethod: PAY_METHOD;
	onClick: (payMethod: PAY_METHOD) => void;
	checked?: boolean;
	loading?: boolean;
	transferQRCode?: QRCode[];
	size?: TSize;
}

const PayMethodCard = ({
	onClick,
	payMethod,
	checked = false,
	loading,
	transferQRCode,
	size = 'medium'
}: PayMethodCardProps) => {
	const handleClick = () => {
		// if (payMethod === PAY_METHOD.TRANSFER) {
		onClick(payMethod);
		// }
	};
	const labelText = () => {
		switch (payMethod) {
			case PAY_METHOD.BANK_CARD:
				return '信用卡';
			case PAY_METHOD.TRANSFER:
				return 'Bank Transfer';
			default:
				return '信用卡';
		}
	};
	const labelIcons = () => {
		const imageStyles: CSSProperties = {
			width: 'auto',
			height: '28px'
		};
		switch (payMethod) {
			case PAY_METHOD.BANK_CARD:
				return (
					<>
						<Image
							src="/image/apply/visa.png"
							alt="visa"
							unoptimized
							loader={imageLoader}
							width={0}
							height={0}
							style={imageStyles}
						/>
						<Image
							src="/image/apply/master_card.png"
							alt="visa"
							unoptimized
							loader={imageLoader}
							width={0}
							height={0}
							style={imageStyles}
						/>
						<Image
							src="/image/apply/airwallex.png"
							alt="airwallex"
							unoptimized
							loader={imageLoader}
							width={0}
							height={0}
							style={imageStyles}
						/>
					</>
				);
			case PAY_METHOD.TRANSFER:
				return '';
			default:
				return '';
		}
	};

	const getTransferQRCode = () => {
		if (transferQRCode && transferQRCode.length > 0) {
			const qrCode = sample<QRCode>(transferQRCode);
			if (qrCode) {
				return (
					<Image
						src={qrCode.qrCode.url}
						alt="jr"
						unoptimized
						loader={imageLoader}
						width={100}
						height={100}
					/>
				);
			}
		}
		return '';
	};

	return (
		<Container>
			{payMethod === PAY_METHOD.TRANSFER && (
				<TitleContainer onClick={handleClick} checked={checked} size={size}>
					<TitleStart>
						<CheckBox>
							{checked && (
								<SelectIcon
									src="/icon/radio-selected.svg"
									alt="select"
									unoptimized
									loader={imageLoader}
									width={16}
									height={16}
								/>
							)}
						</CheckBox>
						{labelText()}
					</TitleStart>
					<TitleEnd>{labelIcons()}</TitleEnd>
				</TitleContainer>
			)}
			{payMethod !== PAY_METHOD.TRANSFER && (
				<TitleContainer onClick={handleClick} checked={checked} size={size}>
					<TitleStart>
						<CheckBox>
							{checked && (
								<SelectIcon
									src="/icon/radio-selected.svg"
									alt="select"
									unoptimized
									loader={imageLoader}
									width={16}
									height={16}
								/>
							)}
						</CheckBox>
						{labelText()}
					</TitleStart>
					<TitleEnd>{labelIcons()}</TitleEnd>
				</TitleContainer>
			)}
			{payMethod === PAY_METHOD.TRANSFER && checked && (
				<ContentContainer>
					<Title>澳洲银行</Title>
					<InfosContainer>
						<InfoItem>BSB: 064000</InfoItem>
						<InfoItem>Account number: 1494 5913</InfoItem>
						<InfoItem>Account Name: JR ACADEMY</InfoItem>
					</InfosContainer>
					<InfosContainer>
						<TipItem>*付款时请在描述中注明您的姓名</TipItem>
						<TipItem>*付款成功后，请截图付款记录并添加匠人客服或与当前客服确认</TipItem>
					</InfosContainer>
					{loading && '加载中...'}
					{!loading && transferQRCode && transferQRCode.length > 0 && getTransferQRCode()}
					{!loading && (!transferQRCode || transferQRCode.length === 0) && (
						<Image
							src="/image/qrcode/jr_wechat_xiaore.jpeg"
							alt="jr"
							unoptimized
							loader={imageLoader}
							width={100}
							height={100}
						/>
					)}
				</ContentContainer>
			)}
		</Container>
	);
};

export default PayMethodCard;
