import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';

import { RootState } from '..';

import { PAY_METHOD } from '@/components/Pages/ApplyPage/ApplyContent/ApplyContentPayMethod/PayMethodCard';
import { APPLY_STEP, LOCAL_STORAGE_CACHE_KEY } from '@/constants/common';
import { ECountryCode } from '@/constants/form';
import { Gender } from '@/constants/user';
import { ApplyDetail } from '@/interfaces/apply';
import { IUser } from '@/interfaces/user';

interface IApplyCourse {
	/** 训练营 id */
	bootcamp?: string;
	/** 课程 id */
	program?: string;
	/**
	 * 是否可改变
	 * 已确定课程，则不可选择其他课程
	 */
	canChange?: boolean;
	/** 价格，仅用于展示 */
	price?: number;
	/** 原价 */
	originalPrice?: number;
	/** 课程名称 */
	name?: string;
	/** 课程封面 */
	thumbnailURL?: string;
	/** 上课方式 */
	type?: string;
	/** 职业 */
	profession?: string;
}
interface IApplyInformation {
	firstName: string;
	lastName: string;
	email: string;
	phoneCountryCode?: ECountryCode;
	phone: string;
	gender?: string;
	/** 工作年数 */
	workingYear: string;
	/** 职业背景 */
	careerBackground: string;
	/** 学历 */
	degree: string;
	/** 技能与兴趣 */
	skillsAndInterests: string;
}
interface IApplyAgree {
	/** 签名 */
	signature: string;
	/** 同意保存签名 */
	agreeSignature?: boolean;
	/** 同意条款 */
	agreeTerms?: boolean;
	agreeContactViaWeChat?: boolean;
}
interface IApplyPay {
	/** 付款方式 */
	paymentMethod: string;
	/** 支付方式 */
	payMethod?: PAY_METHOD;
}

export interface ApplyState {
	/** 订单信息 id - 关联数据库 id */
	id: string;
	/** 当前步骤 */
	step?: APPLY_STEP;
	/**
	 * 选择的课程信息
	 * 对应步骤：APPLY_STEP.PROGRAM_CHOOSE
	 */
	course?: IApplyCourse;
	/**
	 * 信息填写
	 * 后端数据+缓存信息
	 */
	information?: IApplyInformation;
	/** 同意步骤 */
	agree?: IApplyAgree;
	pay?: IApplyPay;
	/** 是否可以进行下一步 */
	canNext: boolean;
}

interface IApplyDetailBeforePayment {
	step: APPLY_STEP;
	bootcamp: string;
	program: string;
	programPrice?: number;
	programName?: string;
	programType?: string;
	/** 是否可以改变选择课程 */
	canChange: boolean;
	user: IUser;
	agreeContactViaWeChat: boolean;
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	phoneCountryCode?: ECountryCode;
}

const initialState: ApplyState = {
	step: APPLY_STEP.PROGRAM_CHOOSE,
	canNext: false,
	id: '',
	course: {
		bootcamp: '',
		program: '',
		canChange: false,
		price: 0,
		originalPrice: 0,
		name: '',
		thumbnailURL: '',
		type: ''
	},
	information: {
		firstName: '',
		lastName: '',
		email: '',
		phoneCountryCode: ECountryCode.AUSTRALIA,
		phone: '',
		gender: Gender.MALE,
		workingYear: '',
		careerBackground: '',
		degree: '',
		skillsAndInterests: ''
	},
	agree: {
		signature: '',
		agreeSignature: false,
		agreeTerms: false,
		agreeContactViaWeChat: false
	},
	pay: {
		paymentMethod: '',
		payMethod: PAY_METHOD.BANK_CARD
	}
};

const updateLocalStorageApply = (state: ApplyState) => {
	localStorage.setItem(LOCAL_STORAGE_CACHE_KEY.apply, JSON.stringify(state));
};

const createLocalStorageApply = (state: ApplyState) => {
	localStorage.removeItem(LOCAL_STORAGE_CACHE_KEY.apply);
	localStorage.setItem(LOCAL_STORAGE_CACHE_KEY.apply, JSON.stringify(state));
};

const applySlice = createSlice({
	name: 'apply',
	initialState,
	reducers: {
		applyInit: state => {
			const detail = JSON.parse(
				localStorage.getItem(LOCAL_STORAGE_CACHE_KEY.apply) || '{}'
			) as ApplyDetail;
			// 结构初始化，防止有的数据没有key
			state.id = detail.id || '';
			state.step = detail.step;
			state.course = {
				bootcamp: detail.course?.bootcamp || '',
				program: detail.course?.program || '',
				canChange: detail.course?.canChange || false,
				price: detail.course?.price,
				originalPrice: detail.course?.originalPrice,
				name: detail.course?.name || '',
				thumbnailURL: detail.course?.thumbnailURL || '',
				type: detail.course?.type || ''
			};
			state.information = {
				firstName: detail.information?.firstName || '',
				lastName: detail.information?.lastName || '',
				email: detail.information?.email || '',
				phoneCountryCode: detail.information?.phoneCountryCode,
				phone: detail.information?.phone || '',
				gender: detail.information?.gender || Gender.MALE,
				workingYear: detail.information?.workingYear || '',
				careerBackground: detail.information?.careerBackground || '',
				degree: detail.information?.degree || '',
				skillsAndInterests: detail.information?.skillsAndInterests || ''
			};
			state.agree = {
				signature: detail.agree?.signature || '',
				agreeSignature: detail.agree?.agreeSignature,
				agreeTerms: detail.agree?.agreeTerms,
				agreeContactViaWeChat: detail.agree?.agreeContactViaWeChat
			};
			state.pay = {
				paymentMethod: detail.pay?.paymentMethod || '',
				payMethod: detail.pay?.payMethod
			};
			// updateLocalStorageApply(state);
		},
		setApplyCanNext: (state, action: PayloadAction<boolean>) => {
			state.canNext = action.payload;
			updateLocalStorageApply(state);
		},
		setApplyCourse: (state, action: PayloadAction<IApplyCourse>) => {
			state.course = {
				...state.course,
				...action.payload
			};
			return updateLocalStorageApply(state);
		},
		setApplyInformation: (state, action: PayloadAction<IApplyInformation>) => {
			state.information = {
				...state.information,
				...action.payload
			};
			updateLocalStorageApply(state);
		},
		setApplyAgree: (state, action: PayloadAction<IApplyAgree>) => {
			state.agree = {
				...state.agree,
				...action.payload
			};
			updateLocalStorageApply(state);
		},
		setApplyPay: (state, action: PayloadAction<IApplyPay>) => {
			state.pay = {
				...state.pay,
				...action.payload
			};
			updateLocalStorageApply(state);
		},
		setApplyId: (state, action: PayloadAction<string>) => {
			state.id = action.payload;
		},
		setApplyStep: (state, action: PayloadAction<APPLY_STEP>) => {
			state.step = action.payload;
			if (!isNumber(state.course?.price)) {
				state.step = APPLY_STEP.PROGRAM_CHOOSE;
			}
			updateLocalStorageApply(state);
		},
		applyClean: state => {
			localStorage.setItem(LOCAL_STORAGE_CACHE_KEY.apply, JSON.stringify({}));
			state.step = APPLY_STEP.PROGRAM_CHOOSE;
			state.canNext = false;
			state.id = '';
			state.course = {
				bootcamp: '',
				program: '',
				canChange: false,
				price: 0,
				originalPrice: 0,
				name: '',
				thumbnailURL: '',
				type: ''
			};
			state.information = {
				firstName: '',
				lastName: '',
				email: '',
				phoneCountryCode: ECountryCode.AUSTRALIA,
				phone: '',
				gender: Gender.MALE,
				workingYear: '',
				careerBackground: '',
				degree: '',
				skillsAndInterests: ''
			};
			state.agree = {
				signature: '',
				agreeSignature: false,
				agreeTerms: false,
				agreeContactViaWeChat: false
			};
			state.pay = {
				paymentMethod: '',
				payMethod: PAY_METHOD.BANK_CARD
			};
		},
		setApplyDetailBeforePayment: (state, action: PayloadAction<IApplyDetailBeforePayment>) => {
			state.step = action.payload.step;
			state.course = {
				bootcamp: action.payload.bootcamp,
				program: action.payload.program,
				canChange: action.payload.canChange,
				price: action.payload.programPrice,
				name: action.payload.programName,
				type: action.payload.programType
			};
			state.information = {
				firstName: action.payload.firstName
					? action.payload.firstName
					: action.payload.user.firstName,
				lastName: action.payload.lastName
					? action.payload.lastName
					: action.payload.user.lastName,
				email: action.payload.email ? action.payload.email : action.payload.user.email,
				phoneCountryCode: action.payload.phoneCountryCode
					? action.payload.phoneCountryCode
					: (`${
							action.payload.user.prefix && action.payload.user.prefix.startsWith('+')
								? action.payload.user.prefix
								: `+${action.payload.user.prefix}`
					  }` as ECountryCode),
				phone: action.payload.phone ? action.payload.phone : action.payload.user.phone,
				gender: action.payload.user.gender || Gender.MALE,
				careerBackground: action.payload.user.careerBackground || '',
				degree: action.payload.user.degree || '',
				skillsAndInterests: action.payload.user.skillsAndInterests || '',
				workingYear: action.payload.user.workingYear || ''
			};
			state.agree = {
				signature: action.payload.user.signature,
				agreeSignature: !!action.payload.user.signature,
				agreeTerms: false,
				agreeContactViaWeChat: action.payload.agreeContactViaWeChat
			};
			createLocalStorageApply(state);
		}
	}
});

export const {
	applyInit,
	setApplyCanNext,
	setApplyCourse,
	setApplyInformation,
	setApplyAgree,
	setApplyPay,
	setApplyId,
	setApplyStep,
	applyClean,
	setApplyDetailBeforePayment
} = applySlice.actions;

export const selectApplyCanNextState = (state: RootState) => state.apply.canNext;
export const selectApplyStepState = (state: RootState) => state.apply.step;
export const selectApplyCourseState = (state: RootState) => state.apply.course;
export const selectApplyInformationState = (state: RootState) => state.apply.information;
export const selectApplyAgreeState = (state: RootState) => state.apply.agree;
export const selectApplyPayState = (state: RootState) => state.apply.pay;
export const selectApplyIdState = (state: RootState) => state.apply.id;
export default applySlice.reducer;
