import Image from 'next/image';
import styled from 'styled-components';

import imageLoader from '@/utils/loader';

const SearchContainer = styled.div`
	border: solid 1px #000;
	border-radius: 2px;
	padding: 5px 16px;
	width: 100%;
`;

const SearchInput = styled.input<{ fontSize: string }>`
	border: none;
	font-size: ${props => props.fontSize};
	margin-left: 10px;
	min-width: 60px;
	width: 100%;
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: #000;
	}
`;

interface SearchBarProps {
	searchValue: string;
	placeholder: string;
	fontSize?: string;
	handleChange: (value: string) => void;
	handleSubmit?: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
	searchValue,
	placeholder = '',
	fontSize = '12px',
	handleChange,
	handleSubmit
}) => {
	return (
		<SearchContainer className="flex items-center bg-white">
			<Image
				src="/image/header-search.svg"
				alt="header-search-icon"
				loader={imageLoader}
				unoptimized
				width={14}
				height={14}
			/>
			<SearchInput
				value={searchValue}
				className="text-black truncate"
				placeholder={placeholder}
				onChange={e => handleChange(e.target.value)}
				fontSize={fontSize}
				onBlur={e => {
					if (handleSubmit) {
						handleSubmit(e.target.value);
					}
				}}
				onKeyDown={e => {
					if (e.key === 'Enter' && handleSubmit) {
						handleSubmit(e.currentTarget.value);
					}
				}}
			/>
		</SearchContainer>
	);
};

export default SearchBar;
