export enum EQRCodeStatus {
	WAITTING_SCAN = 'waitting_scan',
	SUBSCRIBED = 'subscribed',
	HAS_BOUND = 'has_bound',
	OPENID_BEEN_TAKEN = 'openid_been_taken',
	BINDING_SUCCESS = 'binding_success',
	SAME_WECHAT = 'same_wechat',
	ERROR = 'error'
}

export enum EWechatSceneStr {
	LOGIN = 'login',
	BIND = 'bind'
}

export const WECHAT_LOGIN_AUTH_PENDING = 'AUTH_PENDING';

export default {};
